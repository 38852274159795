import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, Navigate, Link, useLocation } from 'react-router-dom';
import { registerUser, addReferral } from '../apiService';
import Footer from './Footer';
import PasswordInput from './PasswordInput';
import { AuthContext } from '../context/AuthContext';

const CreateAccount = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [referralMessage, setReferralMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if (ref) {
      setReferralCode(ref);
      setReferralMessage('Referral code applied. It will be processed after account creation.');
    }
  }, [location]);

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setError('');
    setReferralMessage('');
    try {
      const data = await registerUser(username, password);
      localStorage.setItem('token', data.token);
      setIsAuthenticated(true);
  
      if (referralCode) {
        try {
          await addReferral(referralCode);
          setReferralMessage('Referral code successfully applied!');
        } catch (referralError) {
          console.error('Error adding referral:', referralError);
          setReferralMessage('There was an issue applying the referral code.');
        }
      }
  
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    } catch (error) {
      console.error('Error creating account:', error);
      if (error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError('Account creation failed. Please try again.');
      }
    }
  };

  if (localStorage.getItem('token')) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center pt-16 pb-16">
    <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8 max-w-md w-full">
       
       <div className="flex justify-center mb-6">
          <img src="/peperuney-logo.jpg" alt="PepeRuney Pizza Logo" className="h-20" />
        </div>
        <h2 className="text-2xl font-bold mb-6 text-center text-black">Create Account</h2>
        <form onSubmit={handleCreateAccount} className="space-y-4">
          <div>
            <label className="block text-black mb-1">Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Create a username"
              required
              className={`w-full p-2 rounded bg-white bg-opacity-20 text-black border ${
                error && error.includes('username') ? 'border-red-500' : 'border-gray-300'
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {error && error.includes('username') && (
              <p className="text-red-500 text-sm mt-1">{error}</p>
            )}
          </div>
          <div>
            <label className="block text-black mb-1">Password:</label>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Create a password"
              error={error && error.includes('Password')}
            />
            {error && error.includes('Password') && (
              <p className="text-red-500 text-sm mt-1 bg-red-100 border border-red-400 rounded p-2">
                {error}
              </p>
            )}
          </div>
          <button type="submit" className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            Create Account
          </button>
        </form>
        {referralMessage && <p className="text-green-500 mb-4">{referralMessage}</p>}
      </div>
      <Footer />
    </div>
  );
};

export default CreateAccount;