// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserData, fetchWalletAddresses } from '../apiService';
import Navbar from './Navbar';
import UpdatePassword from './UpdatePassword';
import DashboardContent from './DashboardContent';
import GamesList from './GamesList';
import WalletAddresses from './WalletAddresses';
import CompetitionsPage from './CompetitionsPage';
import ReminderModal from './ReminderModal';
import Footer from './Footer';
import Profile from './Profile';
import JoinTheCrew from './JoinTheCrew';
import SuggestionsModal from './SuggestionsModal';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showGamesList, setShowGamesList] = useState(false);
  const [showWalletAddresses, setShowWalletAddresses] = useState(false);
  const [showCompetitions, setShowCompetitions] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showJoinTheCrew, setShowJoinTheCrew] = useState(false);
  const [showSuggestionsModal, setShowSuggestionsModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserData();
        setUserData(userData);

        const walletData = await fetchWalletAddresses();
        setWalletAddresses(walletData);

        if (!walletData.solana_wallet_address && !walletData.base_eth_wallet_address && !walletData.ton_wallet_address) {
          setShowReminderModal(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUserData(null);
    navigate('/login');
  };

  const handleUpdatePassword = () => {
    setShowUpdatePassword(true);
    setShowGamesList(false);
    setShowWalletAddresses(false);
    setShowCompetitions(false);
    setShowProfile(false);
    setShowJoinTheCrew(false);
  };

  const handleViewGames = () => {
    setShowGamesList(true);
    setShowUpdatePassword(false);
    setShowWalletAddresses(false);
    setShowCompetitions(false);
    setShowProfile(false);
    setShowJoinTheCrew(false);
  };

  const handleViewWalletAddresses = () => {
    setShowWalletAddresses(true);
    setShowUpdatePassword(false);
    setShowGamesList(false);
    setShowCompetitions(false);
    setShowProfile(false);
    setShowJoinTheCrew(false);
  };

  const handleViewCompetitions = () => {
    setShowCompetitions(true);
    setShowUpdatePassword(false);
    setShowGamesList(false);
    setShowWalletAddresses(false);
    setShowProfile(false);
    setShowJoinTheCrew(false);
  };

  const handleViewProfile = () => {
    setShowProfile(true);
    setShowUpdatePassword(false);
    setShowGamesList(false);
    setShowWalletAddresses(false);
    setShowCompetitions(false);
    setShowJoinTheCrew(false);
  };

  const handleJoinTheCrew = () => {
    setShowJoinTheCrew(true);
    setShowProfile(false);
    setShowUpdatePassword(false);
    setShowGamesList(false);
    setShowWalletAddresses(false);
    setShowCompetitions(false);
  };

  const handleBackToDashboard = () => {
    setShowUpdatePassword(false);
    setShowGamesList(false);
    setShowWalletAddresses(false);
    setShowCompetitions(false);
    setShowProfile(false);
    setShowJoinTheCrew(false);
  };

  const handleCloseReminderModal = () => {
    setShowReminderModal(false);
  };

  return (
    <div className="min-h-screen pt-16 pb-16">
      <Navbar
        userData={userData}
        onLogout={handleLogout}
        onUpdatePassword={handleUpdatePassword}
        onViewGames={handleViewGames}
        onViewWalletAddresses={handleViewWalletAddresses}
        onViewCompetitions={handleViewCompetitions}
        onBackToDashboard={handleBackToDashboard}
        onViewProfile={handleViewProfile}
        onJoinTheCrew={handleJoinTheCrew}
      />
      {showUpdatePassword ? (
        <UpdatePassword onBack={handleBackToDashboard} />
      ) : showGamesList ? (
        <GamesList userData={userData} />
      ) : showWalletAddresses ? (
        <WalletAddresses onBack={handleBackToDashboard} />
      ) : showCompetitions ? (
        <CompetitionsPage />
      ) : showProfile ? (
        <Profile />
      ) : showJoinTheCrew ? (
        <JoinTheCrew />
      ) : (
        <DashboardContent userData={userData} />
      )}
      {showReminderModal && (
        <ReminderModal onClose={handleCloseReminderModal} />
      )}
      <Footer onOpenSuggestions={() => setShowSuggestionsModal(true)} />
      <SuggestionsModal isOpen={showSuggestionsModal} onClose={() => setShowSuggestionsModal(false)} />
    </div>
  );
};

export default Dashboard;