// src/components/AmbassadorForm/Availability.js
import React from 'react';

const Availability = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Availability</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">How many hours per week can you dedicate to ambassador activities?</label>
      <input
        type="number"
        name="weekly_hours"
        value={formData.weekly_hours}
        onChange={handleChange}
        required
        min="1"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ringdigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
    </div>
  );
  
  export default Availability;