// src/components/AmbassadorForm/PersonalInfo.js
import React from 'react';
import countries from '../../utils/countries'; // You'll need to create this file with a list of countries

const PersonalInfo = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Personal Information</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Name</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Email Address</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Country of Residence</label>
      <select
        name="country"
        value={formData.country}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >
        <option value="">Select a country</option>
        {countries.map(country => (
          <option key={country} value={country}>{country}</option>
        ))}
        <option value="other">Other</option>
      </select>
    </div>
  </div>
);

export default PersonalInfo;