import React from 'react';

const ReminderModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4 text-center text-black">Reminder</h2>
        <p className="text-black mb-4">
          Please add at least one wallet address to be eligible for PepeRuney rewards.
        </p>
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReminderModal;