import React, { useState, useEffect } from 'react';
import { submitSuggestion, checkCanSubmitSuggestion, getUserSuggestions } from '../apiService';
import { FaTimes } from 'react-icons/fa';

const SuggestionsModal = ({ isOpen, onClose }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const canSubmitData = await checkCanSubmitSuggestion();
        setCanSubmit(canSubmitData.canSubmit);
        const suggestions = await getUserSuggestions();
        setUserSuggestions(suggestions);
      } catch (error) {
        console.error('Error fetching suggestion data:', error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await submitSuggestion(suggestion);
      setCanSubmit(false);
      setSuggestion('');
      const suggestions = await getUserSuggestions();
      setUserSuggestions(suggestions);
    } catch (error) {
      setError('Failed to submit suggestion. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="text-3xl font-bold mb-6 text-center text-black">Peperuney Suggestions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-blue-50 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-blue-800">
              {canSubmit
                ? "Tell us your opinion, suggestions and feedback"
                : "You have already submitted this month"}
            </h3>
            {canSubmit ? (
              <form onSubmit={handleSubmit}>
                <textarea
                  value={suggestion}
                  onChange={(e) => setSuggestion(e.target.value)}
                  className="w-full h-32 p-2 border border-blue-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your suggestion here..."
                  required
                />
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
                >
                  Submit Suggestion
                </button>
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </form>
            ) : (
              <p className="text-blue-700 font-medium">
                Thanks to you we are improving Peperuney every day!
              </p>
            )}
          </div>
          <div className="bg-green-50 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 text-green-800">Your Previous Suggestions</h3>
            {userSuggestions.length > 0 ? (
              <ul className="space-y-4">
                {userSuggestions.map((suggestion, index) => (
                  <li key={index} className="bg-white p-4 rounded-lg shadow">
                    <p className="text-gray-800">{suggestion.suggestion}</p>
                    <p className="text-sm text-gray-500 mt-2">
                      Submitted on: {new Date(suggestion.created_at).toLocaleDateString()}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-green-700">You haven't made any suggestions yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionsModal;