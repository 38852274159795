import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from '../apiService'; // Import the updatePassword function
import PasswordInput from './PasswordInput';

const UpdatePassword = ({ onBack }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('New password and confirm password do not match');
      return;
    }

    try {
      await updatePassword(currentPassword, newPassword);
      alert('Password updated successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Failed to update password', error);
      alert('Failed to update password');
    }
  };

  return (
    <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">Update Password</h2>
      <form onSubmit={handleUpdatePassword} className="space-y-4">
        <div>
          <label className="block text-black mb-1">Current Password:</label>
          <PasswordInput
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder="Enter current password"
          />
        </div>
        <div>
          <label className="block text-black mb-1">New Password:</label>
          <PasswordInput
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Enter new password"
          />
        </div>
        <div>
          <label className="block text-black mb-1">Confirm New Password:</label>
          <PasswordInput
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
          />
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onBack}
            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Back
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Update Password
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePassword;