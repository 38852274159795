import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAvailableCompetitions, submitCompetitionEntry } from '../apiService';

const CompetitionsPage = () => {
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeCompetition, setActiveCompetition] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const competitionsData = await fetchAvailableCompetitions();
        console.log('Competitions Data:', competitionsData);
        setCompetitions(competitionsData);
      } catch (err) {
        setError(`Failed to fetch data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e, competitionId) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    try {
      await submitCompetitionEntry(competitionId, formData);
      alert('Submission successful!');
      setActiveCompetition(null);
    } catch (err) {
      alert(`Failed to submit: ${err.message}`);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">Competitions</h2>
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-black">Available Competitions</h3>
        {competitions.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {competitions.map((competition) => (
              <div key={competition.competition_id} className="bg-glass-white backdrop-blur-xs shadow-lg rounded-lg p-6">
                {activeCompetition === competition.competition_id ? (
                  <form onSubmit={(e) => handleSubmit(e, competition.competition_id)}>
                    <div className="mb-4">
                      <label className="block text-black mb-2">Photo:</label>
                      <input type="file" name="media" className="w-full p-2 border rounded" required />
                    </div>
                    <div className="mb-4">
                      <label className="block text-black mb-2">Social Media URL:</label>
                      <input type="url" name="social_field" className="w-full p-2 border rounded" required />
                    </div>
                    <div className="flex justify-between">
                      <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Submit</button>
                      <button type="button" onClick={() => setActiveCompetition(null)} className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600">Back</button>
                    </div>
                  </form>
                ) : (
                  <>
                    <img src={competition.media_url} alt={competition.title} className="w-full h-48 object-contain rounded-md mb-4" />
                    <h4 className="text-lg font-bold text-black mb-2">{competition.title}</h4>
                    <p className="text-black mb-2">{competition.details}</p>
                    <p className="text-black mb-2"><strong>Terms:</strong> {competition.terms}</p>
                    <p className="text-black mb-2"><strong>Type:</strong> {competition.competition_type}</p>
                    <p className="text-black mb-2"><strong>Tokens for Submission:</strong> {competition.points_for_submission}</p>
                    <p className="text-black mb-2"><strong>Tokens for Winners:</strong> {competition.points_for_winners}</p>
                    <p className="text-black mb-2"><strong>Start Date:</strong> {new Date(competition.start_date).toLocaleDateString()}</p>
                    <p className="text-black mb-2"><strong>End Date:</strong> {new Date(competition.end_date).toLocaleDateString()}</p>
                    <div className="flex justify-between mt-4">
                      <button onClick={() => setActiveCompetition(competition.competition_id)} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Enter</button>
                      <button
                        onClick={() => navigate(`/competitions/${competition.competition_id}/submissions`, { state: { competition } })}
                        className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
                      >
                        View Competition
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-black">No available competitions.</p>
        )}
      </div>
    </div>
  );
};

export default CompetitionsPage;