// src/utils/countries.js
const countries = [
    "United States of America",
    "Canada",
    "United Kingdom of Great Britain and Northern Ireland",
    "Germany",
    "France",
    "Italy",
    "Spain",
    "Australia",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Russian Federation",
    "South Korea",
    "Mexico",
    "South Africa",
    "Turkey",
    "Singapore",
    "Switzerland",
    "Netherlands",
    "Sweden",
    "United Arab Emirates",
    "Saudi Arabia",
    "Argentina",
    "Indonesia",
    "Pakistan",
    "Bangladesh",
    "Sri Lanka",
    "Nepal",
    "Thailand",
    "Malaysia",
    "Vietnam",
    "Philippines",
    "Colombia",
    "Chile",
    "Peru",
    "Nigeria",
    "Kenya",
    "Egypt",
    "Ghana",
    "Ethiopia",
    "Morocco",
    "Tanzania"
]
  
  export default countries;