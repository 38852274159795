import React from 'react';
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

const Footer = ({ onOpenSuggestions }) => {
  const location = useLocation();
  const isAuthPage = location.pathname === '/login' || location.pathname === '/create-account';

  return (
    <footer className={`bg-white bg-opacity-10 backdrop-blur-md shadow-lg p-4 flex ${isAuthPage ? 'justify-center' : 'justify-between'} items-center fixed bottom-0 left-0 right-0 z-50`}>
      <div className={`flex ${isAuthPage ? 'justify-center w-full' : ''}`}>
        <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="mx-4">
          <FaTwitter className="h-8 w-8 text-blue-500 hover:text-blue-700" />
        </a>
        <a href="https://discord.com/invite/yourinvite" target="_blank" rel="noopener noreferrer" className="mx-4">
          <FaDiscord className="h-8 w-8 text-indigo-500 hover:text-indigo-700" />
        </a>
      </div>
      {!isAuthPage && (
        <button onClick={onOpenSuggestions} className="mx-4">
          <img src="/suggestions-icon.png" alt="Suggestions" className="h-8 w-8" />
        </button>
      )}
    </footer>
  );
};

export default Footer;