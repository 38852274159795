import React, { useEffect, useState } from 'react';
import { fetchPointsData, fetchClickToBakeStreak, clickToBake, fetchClickToBakeConfig } from '../apiService';
import { FaInfoCircle } from 'react-icons/fa';

const DashboardContent = ({ userData }) => {
  const [pointsData, setPointsData] = useState(null);
  const [streakData, setStreakData] = useState(null);
  const [hasBaked, setHasBaked] = useState(false);
  const [streakRewardsConfig, setStreakRewardsConfig] = useState(null);
  const [isBakingAvailable, setIsBakingAvailable] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        const points = await fetchPointsData();
        setPointsData(points);
        const streak = await fetchClickToBakeStreak();
        setStreakData(streak);
        setIsBakingAvailable(streak.timeUntilNextClick === 'Bake Now');
        console.log('Streak data:', streak);
        console.log('Is baking available:', streak.timeUntilNextClick === 'Bake Now');
        const config = await fetchClickToBakeConfig();
        setStreakRewardsConfig(config);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    loadData();
  }, []);

  const handleBake = async () => {
    if (!isBakingAvailable) return;
  
    try {
      await clickToBake();
      setHasBaked(true);
      setIsBakingAvailable(false);
      // Refresh streak data after baking
      const streak = await fetchClickToBakeStreak();
      setStreakData(streak);
      
      // Refresh points data after baking
      const points = await fetchPointsData();
      setPointsData(points);
  
      // Reset the animation after 1 second
      setTimeout(() => {
        setHasBaked(false);
      }, 1000);
    } catch (error) {
      console.error('Error baking:', error);
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">Dashboard</h2>

      {/* First section - Points data */}
      {userData && pointsData && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-2 text-black">Current Active Tokens:</h3>
            <p className="text-4xl font-bold text-blue-500">{pointsData.points_to_pay_out}</p>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-2 text-black">Tokens Paid Out:</h3>
            <p className="text-4xl font-bold text-green-500">{pointsData.points_paid_out}</p>
            <button
              className="mt-4 bg-gray-300 text-gray-600 py-2 px-4 rounded cursor-not-allowed"
              title="Coming Soon"
            >
              Request Redemption
            </button>
          </div>
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-2 text-black">Lifetime Points Earned:</h3>
            <p className="text-4xl font-bold text-purple-500">{pointsData.total_points}</p>
          </div>
        </div>
      )}

      {/* Second section - Bake and Streak */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Bake button card */}
        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col items-center relative h-80">
          <div className="absolute top-2 right-2">
            <FaInfoCircle className="text-gray-500 hover:text-gray-700 cursor-pointer" title="Bake daily to earn tokens and build streaks. The longer your streak, the more tokens you earn!" />
          </div>
          <button
            onClick={handleBake}
            disabled={!isBakingAvailable}
            className={`w-48 h-48 rounded-full shadow-lg transform transition-all duration-500 ${isBakingAvailable
                ? 'bg-blue-500 hover:bg-blue-600 hover:scale-105 active:scale-95'
                : 'bg-gray-400 cursor-not-allowed opacity-50'
              }`}
            style={{
              backgroundImage: `url('/peperuney-logo.jpg')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <span className="text-white text-2xl font-bold">
              {isBakingAvailable ? 'Bake Now' : (streakData?.timeUntilNextClick || 'Not Available')}
            </span>
          </button>
          <p className="mt-4 text-center text-black font-semibold">
            Bake daily. Build Streaks. Earn Tokens.
          </p>
        </div>

{/* Streak info card */}
<div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col justify-center h-80 relative">
  <div className="absolute top-2 right-2">
    <FaInfoCircle 
      className="text-gray-500 hover:text-gray-700 cursor-pointer" 
      title="Streaks are earned when you bake pizzas daily. Streaks reset when you don't bake within 24 hours of your next bake period."
    />
  </div>
  <div className="flex justify-between mb-4">
    <div className="bg-blue-100 rounded-lg p-4 flex-1 mr-2">
      <h4 className="text-lg font-semibold text-blue-800 mb-2">Streak:</h4>
      <p className="text-3xl font-bold text-blue-600">{streakData?.currentStreak || 0}</p>
    </div>
    <div className="bg-green-100 rounded-lg p-4 flex-1 ml-2">
      <h4 className="text-lg font-semibold text-green-800 mb-2">Next Bake:</h4>
      <p className="text-sm font-medium text-green-600">{streakData?.timeUntilNextClick || 'N/A'}</p>
    </div>
  </div>
</div>

        {/* Streak Rewards card */}
        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6 flex flex-col h-80">
          <h3 className="text-xl font-bold mb-4 text-black">Streak Rewards</h3>
          <div className="overflow-auto flex-grow">
            {streakRewardsConfig && streakRewardsConfig.streakConfigs ? (
              <table className="w-full text-black">
                <thead className="sticky top-0 bg-white">
                  <tr>
                    <th className="py-2 px-4 text-left">Streak <span role="img" aria-label="fire">🔥</span></th>
                    <th className="py-2 px-4 text-left">Points</th>
                  </tr>
                </thead>
                <tbody>
                  {streakRewardsConfig.streakConfigs.map((config, index) => (
                    <tr key={index} className="border-t border-gray-200">
                      <td className="py-2 px-4">{config.streak_range} </td>
                      <td className="py-2 px-4">{config.points} points</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-600">Loading streak rewards...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;