// src/components/AmbassadorForm/AdditionalInfo.js
import React from 'react';

const AdditionalInfo = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Additional Information</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">How did you hear about our Ambassador Program?</label>
      <input
        type="text"
        name="hear_about_us"
        value={formData.hear_about_us}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Is there anything else you would like us to know about you?</label>
      <textarea
        name="additional_info"
        value={formData.additional_info}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
      ></textarea>
    </div>
  </div>
);

export default AdditionalInfo;