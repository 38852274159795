import React, { useEffect, useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { fetchTwitterVerificationStatus } from '../apiService';

const TwitterStatus = () => {
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        const status = await fetchTwitterVerificationStatus();
        setIsVerified(status.isVerified);
      } catch (error) {
        console.error('Error fetching Twitter verification status:', error);
        setIsVerified(false);
      }
    };

    checkVerificationStatus();

    // Auto-close the window after 10 seconds
    const timer = setTimeout(() => {
      window.close();
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  if (isVerified === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <FaTwitter className="text-blue-400 text-9xl mb-8" />
      {isVerified ? (
        <h1 className="text-3xl font-bold text-green-600">Ball Hard! Your Twitter is Verified</h1>
      ) : (
        <h1 className="text-3xl font-bold text-red-600">Sorry, Twitter isn't Verified</h1>
      )}
      <p className="mt-4 text-gray-600">This window will close automatically in 10 seconds.</p>
    </div>
  );
};

export default TwitterStatus;