import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { fetchApprovedSubmissions } from '../apiService';

const CompetitionSubmissionsPage = () => {
  const { competitionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { competition } = location.state || {};
  const [submissions, setSubmissions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const submissionsData = await fetchApprovedSubmissions(competitionId, currentPage);
        setSubmissions(submissionsData.submissions);
        setTotalPages(submissionsData.total_pages);
      } catch (err) {
        setError(`Failed to fetch data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [competitionId, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const goBack = () => {
    navigate('/competitions');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-8">
      <div className="mb-6">
        <button
          onClick={goBack}
          className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
        >
          Back
        </button>
      </div>
      <h2 className="text-2xl font-bold mb-6 text-center text-black">
        {competition?.title}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {submissions.map((submission) => (
          <div
            key={submission.submission_id}
            className="bg-glass-white backdrop-blur-xs shadow-lg rounded-lg p-6"
          >
            <img
              src={submission.media_url}
              alt={submission.submission_id}
              className="w-full h-48 object-contain rounded-md mb-4"
            />
            <p className="text-black mb-2">
              <strong>Submission ID:</strong> {submission.submission_id}
            </p>
            <p className="text-black mb-2">
              <strong>Winner:</strong>{' '}
              {submission.is_winner ? 'Yes' : 'No'}
            </p>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center">
        <nav aria-label="Pagination">
          <ul className="flex">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (page) => (
                <li key={page}>
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`py-2 px-4 ${
                      currentPage === page
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 text-black'
                    } rounded-md`}
                  >
                    {page}
                  </button>
                </li>
              )
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default CompetitionSubmissionsPage;