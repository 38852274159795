// src/components/AmbassadorForm/ExperienceSkills.js
import React from 'react';

const ExperienceSkills = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Experience and Skills</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Current Occupation/Role</label>
      <input
        type="text"
        name="occupation"
        value={formData.occupation}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Relevant Experience</label>
      <textarea
        name="relevant_experience"
        value={formData.relevant_experience}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
        placeholder="e.g., previous ambassador roles, marketing experience, community builder"
      ></textarea>
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Skills and Expertise</label>
      <textarea
        name="skills"
        value={formData.skills}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
        placeholder="e.g., graphic artist, business, data, content creation, game development"
      ></textarea>
    </div>
  </div>
);

export default ExperienceSkills;