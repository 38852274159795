// src/components/Navbar.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Navbar = ({ userData, onViewGames, onViewWalletAddresses, onViewCompetitions, onBackToDashboard, onViewProfile, onJoinTheCrew }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <nav className="bg-white bg-opacity-10 backdrop-blur-md shadow-lg p-4 flex justify-between items-center fixed top-0 left-0 right-0 z-50">
      <div className="flex items-center">
        <img src="/peperuney-logo.jpg" alt="PepeRuney Pizza Logo" className="h-10 mr-4" />
        <span className="text-xl font-bold text-black">PepeRuney Arcade</span>
      </div>
      <div className="flex items-center">
        {userData && (
          <span className="mr-4 text-black font-bold hidden md:block">Hello, {userData.username}</span>
        )}
        <div className="relative" ref={dropdownRef}>
          <button
            onClick={toggleDropdown}
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Menu
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50">
              <button
                onClick={() => {
                  onBackToDashboard();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Dashboard
              </button>
              <button
                onClick={() => {
                  onViewProfile();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Profile
              </button>
              <button
                onClick={() => {
                  onViewGames();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                View Games
              </button>
              <button
                onClick={() => {
                  onViewWalletAddresses();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Wallet Addresses
              </button>
              <button
                onClick={() => {
                  onViewCompetitions();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Competitions
              </button>
              <button
                onClick={() => {
                  onJoinTheCrew();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Join The Crew
              </button>
              <button
                onClick={() => {
                  handleLogout();
                  setIsDropdownOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-black hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;