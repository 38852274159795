// src/App.js
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './components/Login';
import CreateAccount from './components/CreateAccount';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import CompetitionSubmissionsPage from './components/CompetitionSubmissionsPage';
import JoinTheCrew from './components/JoinTheCrew';
import TwitterStatus from './components/TwitterStatus';
function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/competitions/:competitionId/submissions"
              element={
                <PrivateRoute>
                  <CompetitionSubmissionsPage />
                </PrivateRoute>
              }
            />
            <Route path="/join-the-crew" element={<JoinTheCrew />} />
            <Route
  path="/twitter-status"
  element={
                <PrivateRoute>
                  <TwitterStatus />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;