// src/components/AmbassadorForm/MotivationGoals.js
import React from 'react';

const MotivationGoals = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Motivation and Goals</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Why do you want to become an ambassador for PepeRuneyPizza?</label>
      <textarea
        name="motivation"
        value={formData.motivation}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
      ></textarea>
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">What do you hope to achieve as an ambassador?</label>
      <textarea
        name="goals"
        value={formData.goals}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
      ></textarea>
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">How do you plan to promote our brand/project?</label>
      <textarea
        name="promotion_plans"
        value={formData.promotion_plans}
        onChange={handleChange}
        required
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        rows="4"
      ></textarea>
    </div>
  </div>
);

export default MotivationGoals;