import React, { useEffect, useState } from 'react';
import { fetchGames } from '../apiService';
import GameDetails from './GameDetails';

const GamesList = ({ userData }) => {
  const [games, setGames] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);

  useEffect(() => {
    const getGames = async () => {
      try {
        const data = await fetchGames();
        setGames(data);
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    getGames();
  }, []);

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">View Games & Leaderboards</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {games.map((game) => {
          const base64Image = `data:image/png;base64,${game.game_photo}`;
          const isSelected = selectedGame && selectedGame.game_id === game.game_id;

          return (
            <div
              key={game.game_id}
              className={`bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-4 cursor-pointer ${isSelected ? 'shadow-custom' : ''}`}
              onClick={() => setSelectedGame(game)}
            >
              <div className="aspect-w-16 aspect-h-9 mb-4">
                <img src={base64Image} alt={game.game_name} className="w-full h-full object-contain rounded-md" />
              </div>
              <h3 className="text-lg font-bold text-black text-center">{game.game_name}</h3>
              <p className="text-sm text-black text-center mt-2">{game.game_description}</p>
            </div>
          );
        })}
      </div>
      {selectedGame && (
        <GameDetails gameId={selectedGame.game_id} gameName={selectedGame.game_name} username={userData.username} />
      )}
    </div>
  );
};

export default GamesList;