import axios from './axiosConfig'; // Use the configured Axios instance

// Function to handle user login
export const loginUser = async (username, password) => {
  const response = await axios.post('/api/auth', { username, password });
  return response.data;
};

// Function to handle user registration
export const registerUser = async (username, password) => {
  const response = await axios.post('/api/auth/', { username, password });
  return response.data;
};
// Function to fetch user data
export const fetchUserData = async () => {
  const response = await axios.get('/api/user/profile');
  return response.data;
};

// Function to update user password
export const updatePassword = async (currentPassword, newPassword) => {
  const response = await axios.put('/api/update-password', {
    old_password: currentPassword,
    new_password: newPassword,
  });
  return response.data;
};


// Function to fetch all games
export const fetchGames = async () => {
  const response = await axios.get('/api/games/details');
  return response.data;
};

// Function to fetch wallet addresses
export const fetchWalletAddresses = async () => {
  const response = await axios.get('/api/wallets');
  return response.data;
};

// Function to update wallet addresses
export const updateWalletAddresses = async (walletData) => {
  const response = await axios.put('/api/update-wallets', walletData);
  return response.data;
};

// Function to fetch the top 100 players on the leaderboard for a specific game ID
export const fetchTopPlayers = async (gameId) => {
  const response = await axios.get('/api/leaderboard', {
    params: {
      game_id: gameId,
      limit: 100
    }
  });
  return response.data;
};

// Function to fetch the player's top scores in a specific game
export const fetchPlayerTopScores = async (gameId, username) => {
  const response = await axios.get(`/api/game/${gameId}/user/${username}/top-scores`);
  return response.data;
};



// Function to fetch available competitions
export const fetchAvailableCompetitions = async () => {
  const response = await axios.get('/competitions/user/available-competitions');
  return response.data;
};

export const fetchApprovedSubmissions = async (competitionId, page = 1) => {
  const response = await axios.get(`/competitions/${competitionId}/approved-submissions`, {
    params: {
      page,
    },
  });
  return response.data;
};

// Function to submit a competition entry
export const submitCompetitionEntry = async (competitionId, formData) => {
  const response = await axios.post(`/competitions/competition/${competitionId}/submit`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};


export const fetchPointsData = async () => {
  const response = await axios.get('/api/points/scores');
  return response.data;
};

export const fetchClickToBakeStreak = async () => {
  const response = await axios.get('/api/user/click-to-bake/streak');
  return response.data;
};

export const clickToBake = async () => {
  const response = await axios.post('/api/user/click-to-bake');
  return response.data;
};

export const fetchClickToBakeConfig = async () => {
  const response = await axios.get('/api/user/click-to-bake/config');
  return response.data;
};


export const fetchReferralCode = async () => {
  const response = await axios.get('/api/user/referral-code');
  return response.data;
};

export const fetchReferralStats = async () => {
  const response = await axios.get('/api/user/referral-stats');
  return response.data;
};

export const addReferral = async (referralCode) => {
  const response = await axios.post('/api/user/add-referral', { referralCode });
  return response.data;
};

export const fetchTelegramVerificationStatus = async () => {
  const response = await axios.get('/api/user/telegram-verification-status');
  return response.data;
};

export const initiateTelegramVerification = async () => {
  const response = await axios.post('/api/user/initiate-telegram-verification');
  return response.data;
};

export const fetchTwitterVerificationStatus = async () => {
  const response = await axios.get('/api/user/twitter-verification-status');
  return response.data;
};

export const initiateTwitterVerification = async () => {
  const response = await axios.get('/api/user/initiate-twitter-verification');
  return response.data;
};

export const toggleTelegramNotifications = async (enabled) => {
  const response = await axios.post('/api/user/toggle-telegram-notifications', { enabled });
  return response.data;
};

export const fetchTelegramNotificationsStatus = async () => {
  const response = await axios.get('/api/user/telegram-notifications-status');
  return response.data;
};


export const checkAmbassadorApplicationStatus = async () => {
  const response = await axios.get('/api/user/check-ambassador-application');
  return response.data;
};

export const submitAmbassadorApplication = async (formData) => {
  const response = await axios.post('/api/user/ambassador-application', formData);
  return response.data;
};

export const redeemCoupon = async (code) => {
  const response = await axios.post('/api/user/redeem-coupon', { code });
  return response.data;
};

export const fetchRedeemedCoupons = async () => {
  const response = await axios.get('/api/user/redeemed-coupons');
  return response.data;
};


export const submitSuggestion = async (suggestion) => {
  const response = await axios.post('/api/suggestions', { suggestion });
  return response.data;
};

export const checkCanSubmitSuggestion = async () => {
  const response = await axios.get('/api/suggestions/can-submit');
  return response.data;
};

export const getUserSuggestions = async () => {
  const response = await axios.get('/api/suggestions/user');
  return response.data;
};