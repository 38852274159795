import React, { useState, useEffect } from 'react';
import { fetchReferralCode, fetchReferralStats, addReferral, fetchTelegramVerificationStatus, initiateTelegramVerification, fetchTwitterVerificationStatus, initiateTwitterVerification, toggleTelegramNotifications, fetchTelegramNotificationsStatus, redeemCoupon, fetchRedeemedCoupons } from '../apiService';
import { FaWhatsapp, FaTelegram, FaTwitter, FaCopy, FaCheck, FaTimes } from 'react-icons/fa';
import PasswordUpdateForm from './PasswordUpdateForm';
import ConfirmationModal from './ConfirmationModal';

const Profile = () => {
  const [referralCode, setReferralCode] = useState('');
  const [referralLink, setReferralLink] = useState('');
  const [referralStats, setReferralStats] = useState(null);
  const [newReferralCode, setNewReferralCode] = useState('');
  const [addReferralError, setAddReferralError] = useState('');
  const [telegramStatus, setTelegramStatus] = useState({ isVerified: false, telegramId: null, telegramUsername: null });
  const [telegramDeepLink, setTelegramDeepLink] = useState('');
  const [twitterStatus, setTwitterStatus] = useState({ isVerified: false, twitterUsername: null });
  const [twitterVerificationUrl, setTwitterVerificationUrl] = useState('');
  const [telegramNotificationsEnabled, setTelegramNotificationsEnabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState({ title: '', message: '' });
  const [couponCode, setCouponCode] = useState('');
  const [redeemCouponError, setRedeemCouponError] = useState('');
  const [redeemedCoupons, setRedeemedCoupons] = useState([]);

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        const data = await fetchReferralCode();
        setReferralCode(data.referralCode);
        setReferralLink(data.referralLink);
        const stats = await fetchReferralStats();
        setReferralStats(stats);
      } catch (error) {
        console.error('Error fetching referral data:', error);
      }
    };

    fetchReferralData();
  }, []);

  useEffect(() => {
    const fetchTelegramStatus = async () => {
      try {
        const status = await fetchTelegramVerificationStatus();
        setTelegramStatus(status);
        if (status.isVerified) {
          const notificationsStatus = await fetchTelegramNotificationsStatus();
          setTelegramNotificationsEnabled(notificationsStatus.enabled);
        } else {
          const { deepLink } = await initiateTelegramVerification();
          setTelegramDeepLink(deepLink);
        }
      } catch (error) {
        console.error('Error fetching Telegram status:', error);
      }
    };

    fetchTelegramStatus();
  }, []);

  useEffect(() => {
    const fetchTwitterStatus = async () => {
      try {
        const status = await fetchTwitterVerificationStatus();
        setTwitterStatus(status);
        if (!status.isVerified) {
          const { url } = await initiateTwitterVerification();
          setTwitterVerificationUrl(url);
        }
      } catch (error) {
        console.error('Error fetching Twitter status:', error);
      }
    };

    fetchTwitterStatus();
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const coupons = await fetchRedeemedCoupons();
        setRedeemedCoupons(coupons);
      } catch (error) {
        console.error('Error fetching redeemed coupons:', error);
      }
    };

    fetchCoupons();
  }, []);

  const handleAddReferral = async (e) => {
    e.preventDefault();
    setAddReferralError('');
    try {
      await addReferral(newReferralCode);
      const stats = await fetchReferralStats();
      setReferralStats(stats);
      setNewReferralCode('');
    } catch (error) {
      console.error('Error adding referral:', error);
      setAddReferralError(error.response?.data?.message || 'Failed to add referral. Please try again.');
    }
  };

  const handleToggleTelegramNotifications = () => {
    const newStatus = !telegramNotificationsEnabled;
    if (newStatus) {
      setModalConfig({
        title: 'Enable Notifications',
        message: "Don't miss rewards! Enable Peperuney notifications?",
      });
    } else {
      setModalConfig({
        title: 'Disable Notifications',
        message: "Are you sure you want to disable notifications? Don't miss out on the rewards drop!",
      });
    }
    setIsModalOpen(true);
  };

  const confirmToggleNotifications = async () => {
    try {
      const newStatus = !telegramNotificationsEnabled;
      await toggleTelegramNotifications(newStatus);
      setTelegramNotificationsEnabled(newStatus);
    } catch (error) {
      console.error('Error toggling Telegram notifications:', error);
    }
    setIsModalOpen(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    // You can add a toast notification here to inform the user that the text has been copied
  };

  const shareMessage = `Join PepeRuney Arcade using my referral code: ${referralCode}. Sign up here: ${referralLink}`;

  const handleRedeemCoupon = async (e) => {
    e.preventDefault();
    setRedeemCouponError('');
    try {
      await redeemCoupon(couponCode);
      setCouponCode('');
      const updatedCoupons = await fetchRedeemedCoupons();
      setRedeemedCoupons(updatedCoupons);
    } catch (error) {
      console.error('Error redeeming coupon:', error);
      setRedeemCouponError(error.response?.data?.message || 'Failed to redeem coupon. Please try again.');
    }
  };

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">Profile</h2>
      
      {/* Referrals Section */}
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-black">Referrals</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* First Card: Your Referral Info */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Your Referral Info</h4>
            <div className="mb-4">
              <p className="text-black mb-2">Referral Code:</p>
              <div className="flex items-center">
                <input
                  type="text"
                  value={referralCode}
                  readOnly
                  className="bg-gray-100 text-black p-2 rounded-l w-full"
                />
                <button
                  onClick={() => copyToClipboard(referralCode)}
                  className="bg-blue-500 text-white p-2 rounded-r"
                >
                  <FaCopy />
                </button>
              </div>
            </div>
            <div className="mb-4">
              <p className="text-black mb-2">Referral Link:</p>
              <div className="flex items-center">
                <input
                  type="text"
                  value={referralLink}
                  readOnly
                  className="bg-gray-100 text-black p-2 rounded-l w-full"
                />
                <button
                  onClick={() => copyToClipboard(referralLink)}
                  className="bg-blue-500 text-white p-2 rounded-r"
                >
                  <FaCopy />
                </button>
              </div>
            </div>
            <div className="flex justify-center mt-4 space-x-4">
              <button
                onClick={() => window.open(`https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(shareMessage)}`, '_blank')}
                className="bg-blue-500 text-white p-2 rounded"
              >
                <FaTelegram />
              </button>
              <button
                onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(shareMessage)}`, '_blank')}
                className="bg-green-500 text-white p-2 rounded"
              >
                <FaWhatsapp />
              </button>
              <button
                onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareMessage)}`, '_blank')}
                className="bg-blue-400 text-white p-2 rounded"
              >
                <FaTwitter />
              </button>
            </div>
          </div>

{/* Second Card: Referral Stats */}
<div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
  <h4 className="text-lg font-semibold mb-4 text-black">Referral Stats</h4>
  {referralStats ? (
    <div className="grid grid-cols-2 gap-4">
      <div className="bg-blue-100 rounded-lg p-4">
        <h5 className="text-sm font-semibold text-blue-800 mb-2">Total Referrals</h5>
        <p className="text-2xl font-bold text-blue-600">{referralStats.total_referrals}</p>
      </div>
      <div className="bg-green-100 rounded-lg p-4">
        <h5 className="text-sm font-semibold text-green-800 mb-2">Active Referrals</h5>
        <p className="text-2xl font-bold text-green-600">{referralStats.total_active_referrals}</p>
      </div>
    </div>
  ) : (
    <p className="text-black">Loading referral stats...</p>
  )}
</div>

          {/* Third Card: Add Referral or Show Referrer */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            {referralStats && referralStats.referrer_code ? (
              <>
                <h4 className="text-lg font-semibold mb-4 text-black">Referrer</h4>
                <p className="text-black mb-2">You were referred by:</p>
                <p className="text-xl font-bold text-blue-600">{referralStats.referrer_code}</p>
              </>
            ) : (
              <>
                <h4 className="text-lg font-semibold mb-4 text-black">Add Referral</h4>
                <form onSubmit={handleAddReferral}>
                  <div className="mb-4">
                    <label htmlFor="referralCode" className="block text-sm font-medium text-black mb-2">
                      Enter Referral Code:
                    </label>
                    <input
                      type="text"
                      id="referralCode"
                      value={newReferralCode}
                      onChange={(e) => setNewReferralCode(e.target.value)}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    Add Referral
                  </button>
                  {addReferralError && (
                    <p className="text-red-500 mt-2">{addReferralError}</p>
                  )}
                </form>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Add Socials Earn Rewards Section */}
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-black">Add Socials Earn Rewards</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Telegram Verification Card */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Telegram Verification</h4>
            {telegramStatus.isVerified ? (
              <div>
                <div className="flex items-center space-x-4 mb-4">
                  <FaTelegram className="text-4xl text-blue-500" />
                  <FaCheck className="text-4xl text-green-500" />
                  <span className="text-xl font-semibold text-black">{telegramStatus.telegramUsername}</span>
                </div>
                <div className="flex items-center space-x-4">
                  <span className="text-black">Enable Peperuney Notifications:</span>
                  <button
                    onClick={handleToggleTelegramNotifications}
                    className={`w-8 h-8 border-2 border-black flex items-center justify-center ${
                      telegramNotificationsEnabled ? 'bg-green-500' : 'bg-white'
                    }`}
                  >
                    {telegramNotificationsEnabled ? (
                      <FaCheck className="text-white" />
                    ) : (
                      <FaTimes className="text-red-500" />
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="text-black mb-4">Verify your Telegram account to earn rewards!</p>
                <a
                  href={telegramDeepLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600"
                >
                  <FaTelegram className="mr-2" />
                  Verify Telegram
                </a>
              </div>
            )}
          </div>

          {/* Twitter Verification Card */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Twitter Verification</h4>
            {twitterStatus.isVerified ? (
              <div className="flex items-center space-x-4">
                <FaTwitter className="text-4xl text-blue-400" />
                <FaCheck className="text-4xl text-green-500" />
                <span className="text-xl font-semibold text-black">{twitterStatus.twitterUsername}</span>
              </div>
            ) : (
              <div>
                <p className="text-black mb-4">Verify your Twitter account to earn rewards!</p>
                <a
                  href={twitterVerificationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-400 hover:bg-blue-500"
                >
                  <FaTwitter className="mr-2" />
                  Verify Twitter
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Peperuney Pizza Coupons Section */}
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-black">Peperuney Pizza Coupons</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Redeem Coupon Card */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Add Your Peperuney Pizza Coupons</h4>
            <form onSubmit={handleRedeemCoupon}>
              <div className="mb-4">
                <label htmlFor="couponCode" className="block text-sm font-medium text-black mb-2">
                  Enter Coupon Code:
                </label>
                <input
                  type="text"
                  id="couponCode"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Redeem Coupon
              </button>
              {redeemCouponError && (
                <p className="text-red-500 mt-2">{redeemCouponError}</p>
              )}
            </form>
          </div>

          {/* Redeemed Coupons Card */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Recently Redeemed Coupons</h4>
            <div className="overflow-x-auto max-h-60">
              <table className="w-full text-black">
                <thead className="sticky top-0 bg-white">
                  <tr className="border-b">
                    <th className="py-2 px-4 text-left">Code</th>
                    <th className="py-2 px-4 text-left">Points</th>
                    <th className="py-2 px-4 text-left">Redeemed At</th>
                  </tr>
                </thead>
                <tbody>
                  {redeemedCoupons.map((coupon, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                      <td className="py-2 px-4">{coupon.code}</td>
                      <td className="py-2 px-4">{coupon.points}</td>
                      <td className="py-2 px-4">{new Date(coupon.redeemed_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Account Security Section */}
      <div className="mb-8">
        <h3 className="text-xl font-bold mb-4 text-black">Account Security</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Password Update Card */}
          <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-6">
            <h4 className="text-lg font-semibold mb-4 text-black">Update Password</h4>
            <PasswordUpdateForm />
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmToggleNotifications}
        title={modalConfig.title}
        message={modalConfig.message}
      />
    </div>
  );
};

export default Profile;