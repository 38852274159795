import React, { useState, useEffect } from 'react';
import { fetchTopPlayers, fetchPlayerTopScores } from '../apiService';

const GameDetails = ({ gameId, gameName, username }) => {
  const [view, setView] = useState('leaderboard');
  const [leaderboard, setLeaderboard] = useState([]);
  const [topScores, setTopScores] = useState([]);

  useEffect(() => {
    const loadLeaderboard = async () => {
      try {
        const data = await fetchTopPlayers(gameId);
        setLeaderboard(data);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    const loadTopScores = async () => {
      try {
        const data = await fetchPlayerTopScores(gameId, username);
        setTopScores(data);
      } catch (error) {
        console.error('Error fetching top scores:', error);
      }
    };

    if (view === 'leaderboard') {
      loadLeaderboard();
    } else {
      loadTopScores();
    }
  }, [view, gameId, username]);

  return (
    <div className="p-8">
      <h2 className="text-2xl font-bold mb-4 text-center text-black">{gameName}</h2>
      <div className="flex justify-center mb-4">
        <button
          onClick={() => setView('leaderboard')}
          className={`py-2 px-4 ${view === 'leaderboard' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} rounded-l`}
        >
          Leaderboard
        </button>
        <button
          onClick={() => setView('topScores')}
          className={`py-2 px-4 ${view === 'topScores' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'} rounded-r`}
        >
          Top Scores
        </button>
      </div>
      {view === 'leaderboard' ? (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 text-center">Username</th>
              <th className="py-2 text-center">Highest Score</th>
            </tr>
          </thead>
          <tbody>
            {leaderboard.map((player, index) => (
              <tr key={index}>
                <td className="py-2 text-center">{player.username}</td>
                <td className="py-2 text-center">{player.highest_score}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 text-center">Score</th>
              <th className="py-2 text-center">Date</th>
            </tr>
          </thead>
          <tbody>
            {topScores.map((score, index) => (
              <tr key={index}>
                <td className="py-2 text-center">{score.score}</td>
                <td className="py-2 text-center">{new Date(score.date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GameDetails;