// src/components/JoinTheCrew.js
import React, { useState, useEffect } from 'react';
import { submitAmbassadorApplication, checkAmbassadorApplicationStatus } from '../apiService';
import PersonalInfo from './AmbassadorForm/PersonalInfo';
import SocialMedia from './AmbassadorForm/SocialMedia';
import ExperienceSkills from './AmbassadorForm/ExperienceSkills';
import MotivationGoals from './AmbassadorForm/MotivationGoals';
import Availability from './AmbassadorForm/Availability';
import AdditionalInfo from './AmbassadorForm/AdditionalInfo';

const TOTAL_STEPS = 6;

const JoinTheCrew = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState(() => {
    const savedForm = localStorage.getItem('ambassadorForm');
    return savedForm ? JSON.parse(savedForm) : {
      name: '',
      email: '',
      country: '',
      instagram_handle: '',
      instagram_followers: '',
      twitter_handle: '',
      twitter_followers: '',
      telegram_handle: '',
      discord_handle: '',
      linkedin_handle: '',
      website_url: '',
      occupation: '',
      relevant_experience: '',
      skills: '',
      motivation: '',
      goals: '',
      promotion_plans: '',
      weekly_hours: '',
      hear_about_us: '',
      additional_info: ''
    };
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    const checkApplicationStatus = async () => {
      try {
        const response = await checkAmbassadorApplicationStatus();
        setHasSubmitted(response.hasSubmitted);
      } catch (err) {
        console.error('Error checking application status:', err);
      }
    };

    checkApplicationStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem('ambassadorForm', JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);

    try {
      await submitAmbassadorApplication(formData);
      setSuccess(true);
      setHasSubmitted(true);
      localStorage.removeItem('ambassadorForm');
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while submitting the application.');
    }
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, TOTAL_STEPS));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 1));

  const renderStep = () => {
    switch(currentStep) {
      case 1:
        return <PersonalInfo formData={formData} handleChange={handleChange} />;
      case 2:
        return <SocialMedia formData={formData} handleChange={handleChange} />;
      case 3:
        return <ExperienceSkills formData={formData} handleChange={handleChange} />;
      case 4:
        return <MotivationGoals formData={formData} handleChange={handleChange} />;
      case 5:
        return <Availability formData={formData} handleChange={handleChange} />;
      case 6:
        return <AdditionalInfo formData={formData} handleChange={handleChange} />;
      default:
        return null;
    }
  };

  if (hasSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center pt-16 pb-16">
        <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8 max-w-md w-full text-center">
          <img src="/peperuney-logo.jpg" alt="PepeRuney Logo" className="mx-auto mb-6 h-32" />
          <h2 className="text-2xl font-bold mb-4 text-black">Thank you for applying!</h2>
          <p className="text-black">We will contact you soon for an interview!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center pt-16 pb-16">
      <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8 max-w-2xl w-full">
        <h2 className="text-3xl font-bold mb-6 text-center text-black">Join the Peperuney Ambassador Crew!</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && <p className="text-green-500 mb-4">Application submitted successfully!</p>}
        <form onSubmit={handleSubmit} className="space-y-4">
          {renderStep()}
          <div className="flex justify-between mt-6">
            {currentStep > 1 && (
              <button type="button" onClick={prevStep} className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300">
                Previous
              </button>
            )}
            {currentStep < TOTAL_STEPS ? (
              <button type="button" onClick={nextStep} className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300">
                Next
              </button>
            ) : (
              <button type="submit" className="py-2 px-4 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300">
                Submit Application
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinTheCrew;