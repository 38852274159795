import React, { useState, useEffect } from 'react';
import { fetchWalletAddresses, updateWalletAddresses } from '../apiService';
import { PublicKey } from '@solana/web3.js';
import { ethers } from 'ethers';

const WalletAddresses = ({ onBack }) => {
  const [walletAddresses, setWalletAddresses] = useState({
    solana_wallet_address: '',
    base_eth_wallet_address: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showTonModal, setShowTonModal] = useState(false);

  useEffect(() => {
    const loadWalletAddresses = async () => {
      try {
        const data = await fetchWalletAddresses();
        setWalletAddresses({
          solana_wallet_address: data.solana_wallet_address || '',
          base_eth_wallet_address: data.base_eth_wallet_address || '',
        });
      } catch (err) {
        setError(`Failed to fetch wallet addresses: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    loadWalletAddresses();
  }, []);

  const validateSolanaAddress = (address) => {
    try {
      new PublicKey(address);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateBaseAddress = (address) => {
    return ethers.utils.isAddress(address);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate addresses
    if (walletAddresses.solana_wallet_address && !validateSolanaAddress(walletAddresses.solana_wallet_address)) {
      setError('Invalid Solana address');
      return;
    }
    if (walletAddresses.base_eth_wallet_address && !validateBaseAddress(walletAddresses.base_eth_wallet_address)) {
      setError('Invalid Base address');
      return;
    }

    try {
      await updateWalletAddresses(walletAddresses);
      alert('Wallet addresses updated successfully!');
    } catch (err) {
      setError(`Failed to update wallet addresses: ${err.message}`);
    }
  };

  const connectSolana = async () => {
    console.log('Connecting to Solana...');
    if (window.solana && window.solana.isPhantom) {
      try {
        const response = await window.solana.connect();
        console.log('Solana response:', response);
        setWalletAddresses(prev => ({
          ...prev,
          solana_wallet_address: response.publicKey.toString()
        }));
      } catch (error) {
        setError('Failed to connect to Solana wallet');
      }
    } else {
      setError('Phantom wallet is not installed');
    }
  };

  const connectBase = async () => {
    console.log('Connecting to Base...');
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        console.log('Base address:', address);
        setWalletAddresses(prev => ({
          ...prev,
          base_eth_wallet_address: address
        }));
      } catch (error) {
        if (error.code === 4001) {
          // EIP-1193 userRejectedRequest error
          setError('Connection request was rejected by the user.');
        } else {
          setError('Failed to connect to Base wallet');
        }
      }
    } else {
      setError('MetaMask is not installed');
    }
  };

  const handleTonClick = () => {
    setShowTonModal(true);
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div className="bg-white bg-opacity-10 backdrop-blur-md rounded-lg shadow-lg p-8">
      <h2 className="text-2xl font-bold mb-6 text-center text-black">Wallet Addresses</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-black mb-1">Solana Address:</label>
          <div className="flex">
            <input
              type="text"
              value={walletAddresses.solana_wallet_address}
              onChange={(e) => setWalletAddresses(prev => ({ ...prev, solana_wallet_address: e.target.value }))}
              className="flex-grow p-2 rounded-l bg-white bg-opacity-20 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={connectSolana}
              className="p-2 bg-blue-500 text-white rounded-r hover:bg-blue-600"
            >
              Connect Solana
            </button>
          </div>
        </div>
        <div>
          <label className="block text-black mb-1">Base Address:</label>
          <div className="flex">
            <input
              type="text"
              value={walletAddresses.base_eth_wallet_address}
              onChange={(e) => setWalletAddresses(prev => ({ ...prev, base_eth_wallet_address: e.target.value }))}
              className="flex-grow p-2 rounded-l bg-white bg-opacity-20 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="button"
              onClick={connectBase}
              className="p-2 bg-blue-500 text-white rounded-r hover:bg-blue-600"
            >
              Connect Base
            </button>
          </div>
        </div>
        <div>
          <label className="block text-black mb-1">TON Address:</label>
          <button
            type="button"
            onClick={handleTonClick}
            className="w-full p-2 rounded bg-gray-300 text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add TON Address
          </button>
        </div>
        <div className="flex justify-between">
          <button
            type="button"
            onClick={onBack}
            className="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            Back
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Update Addresses
          </button>
        </div>
      </form>

      {showTonModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4">TON Integration Coming Soon!</h3>
            <p>We're working on integrating TON wallets. Please check back later.</p>
            <button
              onClick={() => setShowTonModal(false)}
              className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletAddresses;