// src/components/AmbassadorForm/SocialMedia.js
import React from 'react';
import { FaInstagram, FaTwitter, FaTelegram, FaDiscord, FaLinkedin, FaLink } from 'react-icons/fa';

const SocialMedia = ({ formData, handleChange }) => (
  <div>
    <h3 className="text-xl font-bold mb-4">Social Media Presence</h3>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaInstagram className="inline mr-2" /> Instagram Handle
      </label>
      <input
        type="text"
        name="instagram_handle"
        value={formData.instagram_handle}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Number of Followers on Instagram</label>
      <input
        type="number"
        name="instagram_followers"
        value={formData.instagram_followers}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaTwitter className="inline mr-2" /> Twitter Handle
      </label>
      <input
        type="text"
        name="twitter_handle"
        value={formData.twitter_handle}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">Number of Followers on Twitter</label>
      <input
        type="number"
        name="twitter_followers"
        value={formData.twitter_followers}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaTelegram className="inline mr-2" /> Telegram Handle
      </label>
      <input
        type="text"
        name="telegram_handle"
        value={formData.telegram_handle}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaDiscord className="inline mr-2" /> Discord Handle
      </label>
      <input
        type="text"
        name="discord_handle"
        value={formData.discord_handle}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaLinkedin className="inline mr-2" /> LinkedIn Handle
      </label>
      <input
        type="text"
        name="linkedin_handle"
        value={formData.linkedin_handle}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
    </div>
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        <FaLink className="inline mr-2" /> Blog/Website URL (if applicable)
      </label>
      <input
        type="url"
        name="website_url"
        value={formData.website_url}
        onChange={handleChange}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      />
      <p className="mt-1 text-sm text-gray-500">Optional</p>
    </div>
  </div>
);

export default SocialMedia;